//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Basic from "../../Shortcode/Price/Basic";
//Install
import Standart from "../../Shortcode/Price/Standart";
//Install
import Premium from "../../Shortcode/Price/Premium";
//Install
import eCommerce from "../../Shortcode/Price/eCommerce";
//Install
import Help from "../../Shortcode/Help/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Ücretlendirme : Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme - Benozgurtosun.com"
        //Element
        Description.content = "İşletmelerin marka bilinirliğini artırarak; dijital başarılarını güvence altına alacak profesyonel web tasarım planları sunulur."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/ucretlendirme"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Ücretlendirme : Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme - Benozgurtosun.com"
        //Element
        Description.content = "İşletmelerin marka bilinirliğini artırarak; dijital başarılarını güvence altına alacak profesyonel web tasarım planları sunulur."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/ucretlendirme"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Ücretlendirme : Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme - Benozgurtosun.com"
        //Element
        Description.content = "İşletmelerin marka bilinirliğini artırarak; dijital başarılarını güvence altına alacak profesyonel web tasarım planları sunulur."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    Resposive = () => {
        //Element
        let Description = document.querySelector( "#React_6M455I" )
        //Element
        if( window.innerWidth >= "320" && window.innerWidth <= "320" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini güçlendirme amacı ile online başarılarını destekleyen, web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "321" && window.innerWidth <= "358" ){
            //Element
            Description.innerHTML = "İşletmelerin, marka bilinirliğini güçlendirme amacı ile online başarılarını destekleyen, web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "359" && window.innerWidth <= "374" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini güçlendirerek, çevrimiçi başarılarını güvence altına alacak, web tasarım planları sunulur."
        //Element
        } else if( window.innerWidth >= "375" && window.innerWidth <= "380" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini artırarak, dijital başarılarını güvence altına alacak, web tasarım planları sunulur."
        //Element
        } else if( window.innerWidth >= "381" && window.innerWidth <= "389" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini artırarak, çevrimiçi başarılarını güvence altına alacak, web tasarım planları sunulur."
        //Element
        } else if( window.innerWidth >= "390" && window.innerWidth <= "391" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini artırarak dijital başarılarını güvence altına alacak planlar sunulur."
        //Element
        } else if( window.innerWidth >= "392" && window.innerWidth <= "394" ){
            //Element
            Description.innerHTML = "İşletmelerin marka bilinirliğini artırarak, dijital başarılarını güvence altına alacak planlar sunulur."
        //Element
        } else if( window.innerWidth >= "395" && window.innerWidth <= "404" ){
             //Element
             Description.innerHTML = "İşletmelerin marka bilinirliğini artırarak, dijital başarılarını güvence altına alacak planlar sunulur."
        //Element
        } else if( window.innerWidth >= "405" && window.innerWidth <= "412" ){
            //Element
            Description.innerHTML = "Marka bilinirliğini güçlendirme amacıyla işletmelerin online başarılarını destekleyen, web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "413" && window.innerWidth <= "415" ){
            //Element
            Description.innerHTML = "Marka bilinirliğini güçlendirme amacıyla işletmelerin dijital başarılarını destekleyen, web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "416" && window.innerWidth <= "479" ){
           //Element
           Description.innerHTML = "Marka bilinirliğini güçlendirme amacıyla işletmelerin çevrimiçi başarılarını destekleyen, web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "480" && window.innerWidth <= "499" ){
            //Element
            Description.innerHTML = "Marka bilinirliğini güçlendirme amacıyla işletmelerin dijital başarılarını destekleyen, profesyonel web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "500" && window.innerWidth <= "599" ){
            //Element
            Description.innerHTML = "İşletmelerin dijital başarılarını sağlamlaştırarak marka bilinirliğini artırmayı hedefleyen, profesyonel web tasarım planları sunulmaktadır."
        //Element
        } else if( window.innerWidth >= "600" && window.innerWidth <= "767" ){
            
        //Element
        } else {

        }
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Ücretlendirme : Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "İşletmelerin marka bilinirliğini artırarak; dijital başarılarını güvence altına alacak profesyonel web tasarım planları sunulur."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
        //Element
        this.Resposive()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemProp: "hasPart", itemScope: "itemScope", itemType: "https://schema.org/CreativeWork" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Bugün 68 Farklı Şehir'de. 5.000 Farklı İşletme.", itemProp: "name alternateName" },
                                    //Element
                                    "Bugün ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "68"
                                    ),
                                    //Element
                                    " Farklı Şehir'de. ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "5.000"
                                    ),
                                    //Element
                                    " Farklı İşletme."
                                ),
                                //Element
                                React.createElement( "p", { id: "React_6M455I", className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "İşletmelerin marka bilinirliğini artırarak, dijital başarılarını güvence altına alacak, ",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    " profesyonel web tasarım planları sunulur."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 h405q4" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Basic, { ID: "0", Title: "Basic", Summary: "Kurumsal Kimlik Edinin", Price: "5.000", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Standart, { ID: "0", Title: "Standard", Summary: "Marka Bilinirliği Oluşturun", Price: "8.000", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Premium, { ID: "0", Title: "Premium", Summary: "Yeni Müşteriler Edinin", Price: "16.000", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( eCommerce, { ID: "0", Title: "Unlimited", Summary: "Online Satışları Artırın", Price: "24.000", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 y7367g" },
                //Element
                React.createElement( Help, { ID: "0", Title: "Ben senin için burada olacağım, bilgi istersen benimle konuşabilirsin.", Summary: "Neye ihtiyacınız olduğundan hâlâ emin değil misin? Hemen arayın. Müşterim olmasanız bile yardımcı olurum." } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;